var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('div',[_c('validation-observer',{ref:"provider"},[_c('b-form',[_c('b-row',[(!_vm.isCefi)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Day Limit In","label-for":"dayLimitIn"}},[_c('validation-provider',{attrs:{"name":"dayLimitIn","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"name"},model:{value:(_vm.form.dayLimitIn),callback:function ($$v) {_vm.$set(_vm.form, "dayLimitIn", _vm._n($$v))},expression:"form.dayLimitIn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2855788895)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Day Limit Out","label-for":"dayLimitOut"}},[_c('validation-provider',{attrs:{"name":"dayLimitOut","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"name"},model:{value:(_vm.form.dayLimitOut),callback:function ($$v) {_vm.$set(_vm.form, "dayLimitOut", _vm._n($$v))},expression:"form.dayLimitOut"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(!_vm.isCefi)?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Month Limit In","label-for":"monthLimitIn"}},[_c('validation-provider',{attrs:{"name":"monthLimitIn","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"name"},model:{value:(_vm.form.monthLimitIn),callback:function ($$v) {_vm.$set(_vm.form, "monthLimitIn", _vm._n($$v))},expression:"form.monthLimitIn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4162426067)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Month Limit Out","label-for":"monthLimitOut"}},[_c('validation-provider',{attrs:{"name":"monthLimitOut","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","state":errors.length > 0 ? false : null,"placeholder":"name"},model:{value:(_vm.form.monthLimitOut),callback:function ($$v) {_vm.$set(_vm.form, "monthLimitOut", _vm._n($$v))},expression:"form.monthLimitOut"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_vm._v(" Edit ")])],1)],1)],1)]),(_vm.isCefi)?[_c('b-card',[_c('p',[_vm._v("The amount of "),_c('strong',[_vm._v("outgoing")]),_vm._v(" threshold can be utilized by:")]),_c('ol',[_c('li',[_vm._v("send funds to own DeFi wallet")]),_c('li',[_vm._v("send funds to other user’s CeFi Fintopio wallet")]),_c('li',[_vm._v("send funds to external wallet")]),_c('li',[_vm._v("place a P2P order to sell crypto")]),_c('li',[_vm._v("close P2P order belongs to other Fintopio user who wants to buy crypto")]),_c('li',[_vm._v("create a voucher")]),_c('li',[_vm._v("send funds via bot in personal chat")])])])]:_c('b-card',[_c('div',[_vm._v(" For DeFi wallets we only regulate buy/sell transaction. "),_c('br'),_vm._v("Incoming threshold is limit for buy crypto by fiat operations. Outgoing threshold is limit for buy fiat by crypto transactions. ")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }