<template>
  <div>
    <b-card>
      <div>
        <validation-observer ref="provider">
          <b-form>
            <b-row>
              <b-col md="6" v-if="!isCefi">
                <b-form-group label="Day Limit In" label-for="dayLimitIn">
                  <validation-provider #default="{ errors }" name="dayLimitIn" rules="required">
                    <b-form-input
                      v-model.number="form.dayLimitIn"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="name"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Day Limit Out" label-for="dayLimitOut">
                  <validation-provider #default="{ errors }" name="dayLimitOut" rules="required">
                    <b-form-input
                      v-model.number="form.dayLimitOut"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="name"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6" v-if="!isCefi">
                <b-form-group label="Month Limit In" label-for="monthLimitIn">
                  <validation-provider #default="{ errors }" name="monthLimitIn" rules="required">
                    <b-form-input
                      v-model.number="form.monthLimitIn"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="name"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group label="Month Limit Out" label-for="monthLimitOut">
                  <validation-provider #default="{ errors }" name="monthLimitOut" rules="required">
                    <b-form-input
                      v-model.number="form.monthLimitOut"
                      type="number"
                      :state="errors.length > 0 ? false : null"
                      placeholder="name"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <!-- Action Buttons -->
            <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click.prevent="save">
              Edit
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </b-card>

    <template v-if="isCefi">
      <b-card>
        <p>The amount of <strong>outgoing</strong> threshold can be utilized by:</p>

        <ol>
          <li>send funds to own DeFi wallet</li>

          <li>send funds to other user’s CeFi Fintopio wallet</li>

          <li>send funds to external wallet</li>

          <li>place a P2P order to sell crypto</li>

          <li>close P2P order belongs to other Fintopio user who wants to buy crypto</li>

          <li>create a voucher</li>

          <li>send funds via bot in personal chat</li>
        </ol>
      </b-card>

<!--      <b-card>-->
<!--        <p>The amount of <strong>incoming</strong> threshold can be utilized by:</p>-->

<!--        <ol>-->
<!--          <li>deposit funds from own DeFi wallet</li>-->

<!--          <li>receive a transfer from other Fintopio DeFi wallet (transfer by TG username)</li>-->

<!--          <li>place a P2P order to buy crypto</li>-->

<!--          <li>close P2P order belongs to other Fintopio user who wants to sell his crypto</li>-->

<!--          <li>activate someone else voucher</li>-->

<!--          <li>apply for funds (in chat)</li>-->
<!--        </ol>-->
<!--      </b-card>-->
    </template>

    <b-card v-else>
      <div>
        For DeFi wallets we only regulate buy/sell transaction. <br/>Incoming threshold is limit for buy crypto by fiat
        operations. Outgoing threshold is limit for buy fiat by crypto transactions.
      </div>
    </b-card>
  </div>
</template>

<script>
import { BButton, BCard, BCol, BForm, BFormGroup, BFormInput, BRow } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import waitRequest from '@/mixins/waitRequest'
import validationError from '@/mixins/validationError'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line
import { required } from '@validations'

export default {
  name: 'WalletLimitsKycEdit',
  components: {
    BRow,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BForm,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
  },
  directives: {
    Ripple,
  },
  mixins: [waitRequest, validationError],

  data() {
    return {
      kycConfigId: null,
      isCefi: true,
      form: {
        dayLimitIn: 100,
        dayLimitOut: 100,
        monthLimitIn: 1000,
        monthLimitOut: 1000,
      },
    }
  },
  mounted() {
    this.initState()
  },
  methods: {
    ...mapActions({
      fetchKYCConfigById: 'kycConfig/fetchKYCConfigById',
      updateKYCConfig: 'kycConfig/updateKYCConfig',
    }),

    initState() {
      this.registerValidator(this.$refs.provider)

      if (!this.$route.params.id) return

      this.kycConfigId = parseInt(this.$route.params.id, 10)
      this.getKYCConfigById(this.kycConfigId)
    },

    update() {
      this.waitRequest(() => {
        return this.updateKYCConfig({ id: this.kycConfigId, form: this.form })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Config is updated',
                icon: 'UserPlusIcon',
                variant: 'info',
              },
            })
            this.$router.go(-1)
          })
          .catch(this.checkErrors)
      })
    },

    getKYCConfigById(kycConfigId) {
      this.waitRequest(() => {
        return this.fetchKYCConfigById(kycConfigId)
          .then(response => {
            this.form = response.data
            this.isCefi = response.data?.type === 'cefi'
          })
          .catch(this.checkErrors)
      })
    },

    async save() {
      const isValid = await this.checkIsValidForm()

      if (this.requestInProgress || !isValid) return

      this.update()
    },
  },
}
</script>
